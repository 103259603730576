import * as React from 'react'
import { useSelector } from 'react-redux'
import { string } from 'prop-types'
import Layout from '@/container/Layout'
import FormProgress from '@/components/widgets/FormProgress/FormProgress'
import withValidActivationUrl from '@/templates/Activation/withValidActivationUrl'
import Spinner from '@/utils/Spinner'
import { isBrowser } from '@/utils'

const ActivationDateOfBirth = React.lazy(() =>
	import('@/templates/Activation/ActivationDateOfBirth')
)
const ActivationPassword = React.lazy(() =>
	import('@/templates/Activation/ActivationPassword')
)

const DOB = 'DOB'
const PASSWORD = 'PASSWORD'

const pages = {
	[DOB]: 1,
	[PASSWORD]: 2
}
function Activation({ id }) {
	const [view, setView] = React.useState(DOB)
	const [progress, setProgress] = React.useState(0)
	const [showClickAwayMessage, setShowClickAwayMessage] = React.useState(false)
	const ref = React.useRef()
	const paths = [
		{ view: DOB, text: 'DOB' },
		{ link: PASSWORD, text: 'Password' },
	]

	const SHOW_DOB = view === DOB
	const SHOW_PASSWORD = view === PASSWORD
	const { firstName } = useSelector(({ user }) => user)

	React.useEffect(() => {
		const node = ref.current

		const onClick = e => {
			const outside = !node.contains(e.target)

			if (outside) {
				e.preventDefault()
			}
			setShowClickAwayMessage(outside)
		}

		if (node && isBrowser) {
			document.addEventListener('click', onClick)
		}

		return () => {
			if (node && isBrowser) {
				document.removeEventListener('click', onClick)
			}
		}
	}, [ref])

	return (
		<Layout className="flex flex-col form-screen pt-lg">
			<div data-testid="activation" className="flex-grow h-full" ref={ref}>
				<FormProgress
					progress={progress}
					page={pages[view]}
					labels={paths}
					className="mb-xl lg:mb-2xl"
				/>
				<div>
					{isBrowser && (
						<React.Suspense
							fallback={
								<div className="fixed inset-0 flex items-center justify-center z-100">
									<Spinner />
								</div>
							}
						>
							{SHOW_DOB && (
								<ActivationDateOfBirth
									name={firstName}
									step={1}
									id={id}
									setProgress={setProgress}
									setView={setView}
									nextView={PASSWORD}
									showClickAwayMessage={showClickAwayMessage}
								/>
							)}
							{SHOW_PASSWORD && (
								<ActivationPassword
									step={2}
									id={id}
									setProgress={setProgress}
									setView={setView}
									showClickAwayMessage={showClickAwayMessage}
								/>
							)}
						</React.Suspense>
					)}
				</div>
			</div>
		</Layout>
	)
}

Activation.propTypes = {
	id: string.isRequired
}

export default withValidActivationUrl(Activation)
