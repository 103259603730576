import * as React from 'react'
import { shape, string } from 'prop-types'
import { useAsync } from 'react-async-hook'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import Loading from '@/components/screens/Loading'
import PageError from '@/components/screens/PageError'
import Text from '@/utils/Text'
import * as restAPI from '@/utils/endpoints'
import fetchApi from '@/utils/fetchApi'
import * as m from '@/utils/messages'

const LOADING = 'LOADING'
const LOADED = 'LOADED'
const ERROR = 'TERMS'

const fetchValidation = async payload =>
	fetchApi(restAPI.TEST_ACTIVATION_URL(payload))

function withValidActivationUrl(Component) {
	const Wrapper = ({ location, ...props }) => {
		const [, token] = location.pathname.split('/').filter(Boolean)
		const [view, setView] = React.useState(LOADING)
		const { loggedIn, showWelcomeModal } = useSelector(state => state.user)
		const dispatch = useDispatch()

		const SHOW_LOADING = view === LOADING
		const SHOW_COMPONENT = view === LOADED
		const SHOW_ERROR = view === ERROR
		const fetchDetails = useAsync(fetchValidation, [token])

		React.useEffect(() => {
			if (loggedIn && showWelcomeModal !== true) {
				navigate('/app/home/')
			}
		}, [loggedIn, showWelcomeModal])

		React.useEffect(() => {
			if (fetchDetails.status === 'success' && loggedIn === false) {
				const { error, status, firstName, surname, email } = fetchDetails.result
				if (typeof error !== 'undefined' || status === 'error') {
					setView(ERROR)
					return
				}

				dispatch({
					type: 'user/SET_DETAILS',
					payload: { firstName, surname, email }
				})

				setView(LOADED)
			}
		}, [fetchDetails.status, fetchDetails.result, dispatch, loggedIn])

		return (
			<>
				{SHOW_LOADING && (
					<Loading data-testid="loading">{m.LOADING_PROFILE}</Loading>
				)}
				{SHOW_COMPONENT && (
					<Component location={location} {...props} id={token} />
				)}
				{SHOW_ERROR && (
					<PageError>
						<Text as="h2" className="text-2xl font-h-light">
							{m.ISSUE_REQUESTING_ACTIVATION_LINK}
						</Text>
					</PageError>
				)}
			</>
		)
	}

	Wrapper.propTypes = {
		location: shape({ pathname: string.isRequired }).isRequired
	}

	return Wrapper
}

export default withValidActivationUrl
